import React, { useEffect } from "react";
import "./translator.css";
import Message from "./Message";

function Translator({ messages, setMessages, sourceLanguage, showToast, setShowToast, sessionID }) {
  useEffect(() => {
    console.log("Messages updated:", messages);
  }, [messages]);

  const handleTextClick = (messageId) => {
    const clickedMessage = messages.find((message) => message.id === messageId);
    if (clickedMessage && clickedMessage.userAudio) {
      try {
        const audioPlayer = new Audio(clickedMessage.userAudio);
        audioPlayer.play();
      } catch (error) {
        console.error("Error playing user audio:", error);
      }
    }
  };

  const handleResponseTextClick = (messageId) => {
    const clickedMessage = messages.find((message) => message.id === messageId);
    if (clickedMessage && clickedMessage.translationAudio) {
      try {
        clickedMessage.translationAudio.play();
      } catch (error) {
        console.error("Error playing translation audio:", error);
      }
    }
  };

  return (
    <>
      <br />
      {messages && messages.length > 0 ? (
        messages.slice(0).reverse().map((item, index) => (
          <Message
            key={`${item.id}-${index}`}
            item={item}
            handleTextClick={handleTextClick}
            handleResponseTextClick={handleResponseTextClick}
            messages={messages}
            setMessages={setMessages}
            showToast={showToast}
            setShowToast={setShowToast}
            sessionID={sessionID}
          />
        ))
      ) : (
        <div></div>
      )}
    </>
  );
}

export default Translator;
