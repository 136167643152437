import { configureStore } from "@reduxjs/toolkit";

import loggedReducer from "./reducers/isLogged";

const store = configureStore({
  reducer: {
    isLogged: loggedReducer,
  },
});

export default store;
