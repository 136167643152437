import "../App.css";
import { useEffect, useState } from "react";
import Navbar from "../components/navbar/Navbar";
import Footermenu from "../components/footermenu/Footermenu";
import Translator from "../components/translator/Translator";
import FeedbackToast from "../components/toast/FeedbackToast";

function Homepage() {
  const [sessionID, setSessionID] = useState(null);
  const [sourceLanguage, setSourceLanguage] = useState("Nederlands");
  const [targetLanguage, setTargetLanguage] = useState("Engels");
  const [messages, setMessages] = useState([]);
  const [languages, setLanguages] = useState();
  const [loading, setLoading] = useState(false);

  const switchAIBaseUrl = "https://translate.bettermos.com/api/v1/";

  useEffect(() => {
    const urlToFetch = `${switchAIBaseUrl}languages`;
    const fetchLanguages = async () => {
      setLoading(true);
      try {
        const response = await fetch(urlToFetch);
        const languages = await response.json();

        setLanguages(languages);
        setSourceLanguage(languages.source_languages[1]);
        setTargetLanguage(languages.source_languages[0]);
      } catch (error) {
        console.error("Error when fetching languages from SwitchAI API:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLanguages();
  }, []);

  useEffect(() => {
    const urlToFetchID = `${switchAIBaseUrl}session-id`;
    const fetchSessionID = async () => {
      try {
        const response = await fetch(urlToFetchID);
        const sessionIDresponse = await response.json();
        setSessionID(sessionIDresponse.session_id);
      } catch (error) {
        console.error("Error when getting sessionID from SwitchAI API:", error);
      }
    };

    fetchSessionID();
  }, []);

  // toast
  const [showToast, setShowToast] = useState({
    toggle: false,
    rating: 0,
    isTranslationFeedback: null,
  });

  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar />

      <div className="container translator">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-11">
              <Translator
                messages={messages}
                setMessages={setMessages}
                sourceLanguage={sourceLanguage}
                showToast={showToast}
                setShowToast={setShowToast}
                sessionID={sessionID}
              />
            </div>
          </div>
        </div>
      </div>
      {!loading ? (
        <Footermenu
          messages={messages}
          setMessages={setMessages}
          sourceLanguage={sourceLanguage}
          targetLanguage={targetLanguage}
          setSourceLanguage={setSourceLanguage}
          setTargetLanguage={setTargetLanguage}
          languages={languages}
          sessionID={sessionID}
        />
      ) : (
        <div className="container text-center my-3">
          <div className="row">
            <div className="col">
              <div className="spinner-border text-secondary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
            <div className="col">
              <div className="spinner-border text-secondary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          </div>
        </div>
      )}
      <FeedbackToast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
}

export default Homepage;
