import "../App.css";

import Authenticator from "../components/authenticator/Authenticator";
import SignIn from "../components/authenticator/SignIn";
import SignUp from "../components/authenticator/SignUp";
import Navbar from "../components/navbar/Navbar";
///\\\\///\\\\
function Loginpage() {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar />
      <div className="row my-5">
        <div className="col-12 px-5 pt-5">
          <Authenticator />
          <SignIn />
        </div>
      </div>
    </div>
  );
}

export default Loginpage;
