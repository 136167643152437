import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Get the navigate function

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const submitSignIn = (e) => {
    e.preventDefault(); //so form doesnt reload
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredentials) => {
        dispatch({ type: "SIGN_IN" });
        navigate("/Homepage");
      })
      .catch((error) => {
      });
  };

  return (
    <form onSubmit={submitSignIn}>
      <div className="mb-3">
        <label htmlFor="InputEmail1" className="form-label">
          E-mailadres
        </label>
        <input
          type="email"
          className="form-control"
          id="inputEmail1"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        ></input>
      </div>
      <div className="mb-3">
        <label htmlFor="exampleInputPassword1" className="form-label">
          Wachtwoord
        </label>
        <input
          type="password"
          className="form-control"
          id="exampleInputPassword1"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </div>
      <button type="submit" className="btn btn-primary me-2">
        Inloggen
      </button>
    </form>
  );
}

export default SignIn;
