import React from "react";
import logo from "../../MOKIN_WHITE_TRANSP.png";
import "./Navbar.css";
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("signout successful");
        navigate("/Loginpage");
      })
      .catch((error) => console.log(error));
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
      <div className="container-fluid mx-3">
        <a className="navbar-brand" href="#">
          <img
            src={logo}
            height="35"
            className="d-inline-block align-top"
            alt="Mokin Logo"
          />
        </a>

        <button
          className="navbar-toggler collapsed d-flex d-lg-none flex-column justify-content-around"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDarkDropdown"
          aria-controls="navbarNavDarkDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon top-bar"></span>
          <span className="navbar-toggler-icon middle-bar"></span>
          <span className="navbar-toggler-icon bottom-bar"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDarkDropdown">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="modal"
                data-bs-target="#helpModal"
                href="#"
              >
                Hulp
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="modal"
                data-bs-target="#aboutModal"
                href="#"
              >
                Over Mokin
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#" onClick={handleLogout}>
                Uitloggen &nbsp;<i className="bi bi-box-arrow-right"></i>
              </a>
            </li>
          </ul>
        </div>

        {/* Help Modal */}
        <div
          className="modal fade"
          id="helpModal"
          tabIndex="-1"
          aria-labelledby="helpModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="helpModalLabel">
                  Hoe gebruik ik Mokin?
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p>
                  Selecteer onderin het beeldscherm jouw eigen taal en de taal
                  waar naar je wilt vertalen.
                </p>
                <p>
                  Klik op de microfoonknop en begin met praten. Druk de knop
                  nogmaals in als je klaar bent met praten. De gesproken tekst
                  wordt nu automatisch vertaald en uitgesproken.
                </p>
                <p>Klik op de tekst om de vertaling nogmaals te horen!</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Sluiten
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* About Modal */}
        <div
          className="modal fade"
          id="aboutModal"
          tabIndex="-1"
          aria-labelledby="aboutModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="aboutModalLabel">
                  Over Mokin®
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p>
                  Mokin is een mobiele app gemaakt door SwitchAI. Voor meer
                  informatie ga naar:
                </p>
                <a href="https://switchai.nl" target="_blank" rel="noopener noreferrer">
                  www.switchai.nl
                </a>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Sluiten
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
