import React from "react";

const MicButton = ({ btnClass, onClick, loading, listening, micInstance, micId, spinnerClass, blinkIconClass }) => {
  return (
    <button
      id="micpopper"
      aria-describedby="tooltip"
      className={btnClass}
      onClick={onClick}
      type="button"
    >
      <i
        className={
          loading
            ? spinnerClass
            : listening && micInstance === micId
            ? blinkIconClass
            : "bi bi-mic display-5"
        }
        role="status"
      ></i>
    </button>
  );
};

export default MicButton;