import { useState } from "react";
import { RecordRTCPromisesHandler, StereoAudioRecorder } from "recordrtc";

export const useMicrophone = () => {
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [microphone, setMicrophone] = useState(null);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const recorder = new RecordRTCPromisesHandler(stream, {
      type: "audio",
      recorderType: StereoAudioRecorder,
      mimeType: "audio/wav",
      sampleRate: 48000,
      numberOfAudioChannels: 1,
    });

    await recorder.startRecording();
    setMediaRecorder(recorder);
    setMicrophone(stream);
  };

  const stopRecording = async () => {
    if (mediaRecorder) {
      await mediaRecorder.stopRecording();
      const audioBlob = await mediaRecorder.getBlob();
      mediaRecorder.reset();
      setMediaRecorder(null);
      return audioBlob;
    }
    return null;
  };

  const cleanup = () => {
    if (microphone) {
      microphone.getTracks().forEach((track) => track.stop());
      setMicrophone(null);
    }
  };

  return { startRecording, stopRecording, cleanup };
};