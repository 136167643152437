import React, { useEffect, useState } from "react";
import { auth } from "../../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Homepage from "../../pages/Homepage";

function Authenticator() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
        dispatch({ type: "SIGN_IN" });
        navigate("/Homepage");
      } else {
        setAuthUser(null);
      }
    });
    return () => {
      listen();
    };
  }, []);

  const userSignOut = () => {
    signOut(auth)
      .then(() => {
        Navigate("/Loginpage");
      })
      .catch((error) => console.log(error));
  };
  return (
    <div>
      {authUser ? (
        <div>
          <p>{`Signed in as ${authUser.email}`}</p>
          <button type="button" className="btn btn-secondary" onClick={userSignOut}>
            Sign out
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Authenticator;
