// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
console.log("firebaseinit initialized ");

const firebaseConfig = {
  apiKey: "AIzaSyBMaY7hhVac3zdWdg1luK-6LNbqvCCIv_w",
  authDomain: "switchai-translator.firebaseapp.com",
  projectId: "switchai-translator",
  storageBucket: "switchai-translator.appspot.com",
  messagingSenderId: "339887078864",
  appId: "1:339887078864:web:a3c7d0ef9ed68f611f8dd5",
  measurementId: "G-BJTGEXQP6L",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
