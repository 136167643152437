import React, { useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";

function SignUp() {
  // Initialize Firebase
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate(); // Get the navigate function

  const submitSignUp = (e) => {
    e.preventDefault(); //so form doesnt reload
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredentials) => {
        console.log(userCredentials);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <form onSubmit={submitSignUp}>
      <div className="mb-3">
        <h1>Create account</h1>
        <label htmlFor="inputEmail2" className="form-label">
          Email address
        </label>
        <input
          type="email"
          className="form-control"
          id="inputEmail2"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        ></input>
      </div>
      <div className="mb-3">
        <label htmlFor="inputPassword2" className="form-label">
          Password
        </label>
        <input
          type="password"
          className="form-control"
          id="inputPassword2"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </div>
      {/* <div class="mb-3 form-check">
        <input type="checkbox" class="form-check-input" id="exampleCheck1" />
        <label class="form-check-label" htmlFor="exampleCheck1">
          Check me out
        </label>
      </div> */}
      <div>
        <button type="submit" className="btn btn-primary me-2">
          Submit
        </button>
        <button
          onClick={() => {
            navigate("/loginpage");
          }}
          className="btn btn-secondary"
        >
          Back
        </button>
      </div>
    </form>

    // <div className="signin-container">
    //   <form onSubmit={submitSignUp}>
    //     <h1>Create new account</h1>
    //     <input
    //       type="text"
    //       placeholder="enter email"
    //       value={email}
    //       onChange={(e) => {
    //         setEmail(e.target.value);
    //       }}
    //     ></input>
    //     <input
    //       type="password"
    //       placeholder="enter password"
    //       value={password}
    //       onChange={(e) => {
    //         setPassword(e.target.value);
    //       }}
    //     ></input>
    //     <button type="submit">Sign Up</button>
    //   </form>
    // </div>
  );
}

export default SignUp;
