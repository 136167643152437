import React, { useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import "./feedbacktoast.css";

export default function FeedbackToast({ showToast, setShowToast }) {

  function getToastMessage(isTranslationFeedback){
    return isTranslationFeedback ? 'vertaling' : 'transcriptie';
  }

  return (
    <ToastContainer position="top-center">
      <Toast
        className="topmargin"
        show={showToast.toggle ? true : false}
        onClose={() => {
          setShowToast({ toggle: false, rating: 0 });
        }}
        position="top-center"
        delay={2000}
        autohide
      >

        <Toast.Body>{`Je hebt deze ${getToastMessage(showToast.isTranslationFeedback)} als ${
          showToast.rating == 2 ? "goed" : "fout"
        } beoordeeld`}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}
