import React, { useState } from "react";
import { useSpeechRecognition } from "react-speech-recognition";
import Microphone1 from "../microphone/Microphone1";
import Microphone2 from "../microphone/Microphone2";
import LanguageDropdown from "./LanguageDropdown";
import "./footermenu.css";

function Footermenu({
  messages,
  setMessages,
  sourceLanguage,
  targetLanguage,
  setSourceLanguage,
  setTargetLanguage,
  languages,
  sessionID,
}) {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const [micInstance, setMicInstance] = useState("");

  const handleClickSourceLanguage = (language) => {
    setSourceLanguage(language);
  };

  const handleClickTargetLanguage = (language) => {
    setTargetLanguage(language);
  };

  return (
    <div className="container text-center position-fixed my-3 bottom-0 footermenu">
      <div className="row">
        <div className="col">
          <Microphone1
            transcript={transcript}
            resetTranscript={resetTranscript}
            browserSupportsSpeechRecognition={browserSupportsSpeechRecognition}
            messages={messages}
            setMessages={setMessages}
            sourceLanguage={sourceLanguage}
            targetLanguage={targetLanguage}
            micInstance={micInstance}
            setMicInstance={setMicInstance}
            sessionID={sessionID}
          />
        </div>
        <div className="col">
          <Microphone2
            transcript={transcript}
            resetTranscript={resetTranscript}
            browserSupportsSpeechRecognition={browserSupportsSpeechRecognition}
            messages={messages}
            setMessages={setMessages}
            sourceLanguage={targetLanguage}
            targetLanguage={sourceLanguage}
            micInstance={micInstance}
            setMicInstance={setMicInstance}
            sessionID={sessionID}
          />
        </div>
      </div>
      <div className="row text-center">
        {languages && (
          <>
            <LanguageDropdown
              languages={languages.source_languages}
              selectedLanguage={sourceLanguage}
              handleClick={handleClickSourceLanguage}
              label="Selecteer taal"
            />
            <LanguageDropdown
              languages={languages.target_languages}
              selectedLanguage={targetLanguage}
              handleClick={handleClickTargetLanguage}
              label="Selecteer taal"
            />
          </>
        )}
      </div>
    </div>
  );
}

export default Footermenu;
