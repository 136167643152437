import React from "react";

function LanguageDropdown({ languages, selectedLanguage, handleClick, label }) {
  return (
    <div className="col col-6">
      <div className="btn-group dropup w-100">
        <button
          type="button"
          className="btn btn-link text-decoration-none dropdown-toggle fs-4 text-wrap"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {selectedLanguage && selectedLanguage.display_name}
        </button>
        <ul className="dropdown-menu">
          <li>
            <h6 className="dropdown-header">{label}</h6>
          </li>
          {languages &&
            languages.map((language, index) => (
              <a
                className="dropdown-item"
                key={index}
                onClick={() => handleClick(language)}
                href="#"
              >
                {language.display_name}
              </a>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default LanguageDropdown;