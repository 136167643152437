import React from "react";
import MicrophoneBase from "./MicrophoneBase";
import "./microphone1.css";

function Microphone1(props) {
  return (
    <MicrophoneBase
      {...props}
      micId="A"
      targetGender="male"
      tooltipClass="tooltip1"
      btnClass="btn btn-primary micbtn custom-mic1-btn"
      spinnerClass="spinner-border text-light"
      blinkIconClass="bi bi-stop-circle blink-icon1 display-3"
      iconClass="text-white"
    />
  );
}

export default Microphone1;
